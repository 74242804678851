import { usePathname } from 'next/navigation';

import { MouseEvent } from 'react';

import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { referAFriendBanner } from '@shared/assets/images';
import NextLink, { NextLinkProps } from '@shared/common/components/NextLink';
import { useCompleteMagicIdModal } from '@shared/common/providers/GlobalModalsProvider';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const RAF_PATHNAME = '/refer-a-friend';

export interface RAFBannerProps extends Omit<NextLinkProps, 'href'> {
  className?: string;
}

const ReferAFriendBanner = ({ className, onClick, ...props }: RAFBannerProps) => {
  const { isAuth, user } = useAuth();
  const completeMagicIdModal = useCompleteMagicIdModal();
  const pathname = usePathname();
  const isDisabled = pathname === RAF_PATHNAME;

  const handleRafBannerClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(event);
    }

    if (isDisabled) {
      event.preventDefault();
      return;
    }

    if (!user?.attributes?.name) {
      event.preventDefault();

      completeMagicIdModal.open({
        description: {
          name: null,
        },
        success: false,
      });

      return;
    }
  };

  if (!isAuth) {
    return null;
  }

  return (
    <NextLink
      href={RAF_PATHNAME}
      onClick={handleRafBannerClick}
      className={classNames('w-full block', className)}
      {...props}
    >
      <ImageProxy
        src={referAFriendBanner}
        width={624}
        height={472}
        alt="Banner refer a friend"
        className="w-full"
      />
    </NextLink>
  );
};

export default ReferAFriendBanner;

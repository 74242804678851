import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { forwardRef, Ref } from 'react';

import { useIsPathnameLinkHref } from '@store:web/utils/hooks/usePathnameMatchUrl';

import SidebarNavItem, { SidebarNavItemProps } from './SidebarNavItem';

type SidebarNavLinkProps = Omit<SidebarNavItemProps<typeof Link>, 'isActive'> & {
  isActive?: ((params: { pathname: string }) => boolean) | boolean;
};

const SidebarNavLink = (
  { isActive, href, ...props }: SidebarNavLinkProps,
  ref: Ref<HTMLAnchorElement>,
) => {
  const pathname = usePathname();
  const isMatch = useIsPathnameLinkHref(href);
  const active = typeof isActive === 'function' ? isActive({ pathname }) : isActive || isMatch;

  return <SidebarNavItem ref={ref} href={href} isActive={active} {...props} />;
};

export default forwardRef(SidebarNavLink);

'use client';

import { IntlError, NextIntlClientProvider } from 'next-intl';

import { ReactNode } from 'react';

import { TranslationList } from '@api';

import { GLOBAL_CONFIG } from '@store:web/utils/config/global';

interface I18nProviderProps {
  locale?: string;
  translations?: TranslationList;
  children?: ReactNode;
}

export const DEFAULT_LOCALE = 'en';

const I18nProvider = ({ locale = DEFAULT_LOCALE, translations, children }: I18nProviderProps) => {
  const localeTranslations = translations && (translations[locale] || translations[DEFAULT_LOCALE]);

  const handleError = (error: IntlError) => {
    if (GLOBAL_CONFIG.isDev) {
      console.error(error);
    }
  };

  return (
    <NextIntlClientProvider onError={handleError} locale={locale} messages={localeTranslations}>
      {children}
    </NextIntlClientProvider>
  );
};

export default I18nProvider;

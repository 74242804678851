import Link from 'next/link';

import {
  ComponentPropsWithoutRef,
  ComponentType,
  ElementType,
  forwardRef,
  ReactNode,
  Ref,
} from 'react';

import classNames from 'classnames';

import I18nInfoTooltip from '@store:web/components/common/I18nInfoTooltip/I18nInfoTooltip';

import NavbarItemIcon from './NavbarItemIcon';

export interface NavbarItemGeneralProps {
  Icon?: ComponentType;
  tooltip?: {
    title: string;
    content: string;
  };
  label?: ReactNode;
  compact?: boolean;
  isActive?: boolean;
  children?: ReactNode;
  className?: string;
  activeClassName?: string;
  labelClassName?: string;
}

export type NavbarItemProps<C extends ElementType> = NavbarItemGeneralProps & {
  Component?: C;
} & ComponentPropsWithoutRef<C>;

const NavbarItem = <C extends ElementType = typeof Link>(
  {
    Component,
    isActive,
    label,
    className,
    children,
    Icon,
    labelClassName,
    activeClassName = 'text-neutral-600',
    compact,
    tooltip,
    ...props
  }: NavbarItemProps<C>,
  ref: Ref<ElementType<C>>,
) => {
  const As: ElementType = Component || Link;
  const isLink = As === Link;

  return (
    <As
      className={classNames(
        'flex flex-col justify-center items-center group flex-1 max-w-[6rem] flex-shrink-0 transition-colors gap-1',
        isActive ? activeClassName : 'text-neutral-200',
        className,
      )}
      ref={ref}
      {...props}
      {...(isLink ? { prefetch: false } : {})}
    >
      <span className={classNames('w-9 h-9 flex justify-center shrink-0 items-center')}>
        {Icon && <NavbarItemIcon Icon={Icon as any} isActive={isActive} />}
        {children}
      </span>
      <span
        className={classNames(
          'whitespace-nowrap text-center text-neutral-200 w-auto max-sm:text-xs',
          compact ? 't-overline' : 't-subtitle3 font-bold',
          isActive ? activeClassName : 'text-neutral-200',
          labelClassName,
        )}
      >
        {!tooltip && label}

        {tooltip && (
          <I18nInfoTooltip {...tooltip} className="text-neutral-200">
            {label}
          </I18nInfoTooltip>
        )}
      </span>
    </As>
  );
};

export default forwardRef(NavbarItem) as <C extends ElementType>(
  props: NavbarItemProps<C>,
) => JSX.Element;

import { useEffect } from 'react';

import Cookies from 'js-cookie';

import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';

import { useAuth } from '@store:web/components/providers/AuthProvider';
import useDetectCampaign from '@store:web/utils/hooks/useDetectCampaign';
// import useFirstRenderAuth from '@store:web/utils/hooks/useFirstRenderAuth';
import useHandleEmailLoginError from '@store:web/utils/hooks/useHandleEmailLoginError';
import useOneSignal from '@store:web/utils/hooks/useOneSignal';
import usePingUserAction from '@store:web/utils/hooks/usePingUserAction';
import useShowAuthModalOnLogout from '@store:web/utils/hooks/useShowAuthModalOnLogout';
import useListenAccountChange from '@store:web/utils/hooks/useWeb3/useListenToAccountChange';

import useTrackEmailAuth from './useTrackEmailAuth';

const ClientSideHooks = () => {
  // useFirstRenderAuth();
  useShowAuthModalOnLogout();
  useHandleEmailLoginError();
  useListenAccountChange();
  useDetectCampaign();
  usePingUserAction();
  useOneSignal();
  useTrackEmailAuth();

  const authModal = useAuthModal();
  const { isAuth, isLoading } = useAuth();

  useEffect(() => {
    const cookieKey = 'show_signup';
    const hasCookie = Cookies.get(cookieKey) === 'true';

    if (!isLoading && !isAuth && hasCookie) {
      authModal.open();
      Cookies.remove(cookieKey);
    }
  }, [authModal, isAuth, isLoading]);

  return null;
};

export default ClientSideHooks;

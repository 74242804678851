import Link from 'next/link';

import {
  ComponentPropsWithoutRef,
  ComponentType,
  ElementRef,
  ElementType,
  forwardRef,
  ReactNode,
  Ref,
} from 'react';

import classNames from 'classnames';

export interface SidebarNavItemGeneralProps {
  Icon?: ComponentType;
  label?: ReactNode;
  isActive?: boolean;
  compact?: boolean;
  children?: ReactNode;
  className?: string;
  iconsClassName?: string;
}

export type SidebarNavItemProps<C extends ElementType> = SidebarNavItemGeneralProps & {
  Component?: C;
} & ComponentPropsWithoutRef<C>;

const SidebarNavItem = <C extends ElementType = typeof Link>(
  {
    Component,
    isActive,
    label,
    compact,
    className,
    children,
    Icon,
    iconClassName,
    ...props
  }: SidebarNavItemProps<C>,
  ref: Ref<ElementRef<C>>,
) => {
  const As = Component || Link;
  const isLink = As === Link;

  return (
    <As
      className={classNames(
        'flex flex-col justify-center items-center transition-colors gap-1.5 group',
        isActive ? 'text-neutral-50' : 'text-neutral-200',
        className,
      )}
      ref={ref}
      // TODO: fix type
      {...(props as any)}
      {...(isLink ? { prefetch: false } : {})}
      data-qa="sidebar-item"
    >
      <span className={classNames('w-10 h-10 flex justify-center items-center')}>
        {Icon && (
          <span
            className={classNames(
              'w-full h-full flex justify-center items-center rounded-[10px] text-neutral-50 bg-neutral-600 transition-shadow',
              isActive ? 'shadow-nav-item' : 'opacity-60 group-hover:shadow-nav-item',
            )}
          >
            <span className={classNames('icon-lg', iconClassName)}>
              <Icon />
            </span>
          </span>
        )}
        {children}
      </span>
      <span
        className={classNames(
          'font-medium truncate w-full text-center text-3xs',
          compact ? 't-overline' : 't-body4',
        )}
      >
        {label}
      </span>
    </As>
  );
};

export default forwardRef(SidebarNavItem);

import { forwardRef, Ref } from 'react';

import SidebarNavItem, { SidebarNavItemProps } from './SidebarNavItem';

type SidebarNavButtonProps = SidebarNavItemProps<'button'>;

const SidebarNavButton = (props: SidebarNavButtonProps, ref: Ref<HTMLButtonElement>) => {
  return <SidebarNavItem ref={ref} Component="button" {...props} />;
};

export default forwardRef(SidebarNavButton);

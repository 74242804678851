import { useCallback, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const createOneSignalConnect = async () => {
  await OneSignal.init({
    appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID || '',
    allowLocalhostAsSecureOrigin: !!process.env.NEXT_PUBLIC_ONE_SIGNAL_ALLOW_LOCALHOST,
    notifyButton: {
      enable: true,
    },
  });
  OneSignal.Slidedown.promptPush();
  return OneSignal;
};

const useOneSignal = () => {
  const [oneSignalProxy, setOneSignalProxy] = useState<typeof OneSignal | null>(null);
  const { notification } = useFeatureToggles();
  const { isAuth, user } = useAuth();

  const initNotification = useCallback(async () => {
    try {
      if (isAuth && oneSignalProxy) {
        await oneSignalProxy.login(String(user.id));
        await oneSignalProxy.User.addAlias('userId', String(user.id));
      }
    } catch (error) {
      console.error(error);
    }
  }, [oneSignalProxy, isAuth, user]);

  const initConnect = useCallback(async () => {
    if (!notification) {
      return setOneSignalProxy(null);
    }

    const connect = await createOneSignalConnect();
    setOneSignalProxy(connect);
  }, [notification]);

  useEffect(() => {
    if (notification) {
      initConnect();
    }
  }, [notification, initConnect]);

  useEffect(() => {
    if (notification && oneSignalProxy) {
      initNotification();
    }
  }, [notification, oneSignalProxy, initNotification, isAuth]);
};

export default useOneSignal;

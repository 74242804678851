import { useRouter } from 'next/navigation';

import { useEffect } from 'react';

import Cookies from 'js-cookie';

import { useTagManager } from '@shared/common/providers/GoogleTagManagerProvider';

const getActiveCampaigns = () => {
  try {
    return [...(JSON.parse(Cookies.get('activeCampaigns') || '') || [])];
  } catch (error) {
    return [];
  }
};

const setActiveCampaigns = (campaigns: string[]) => {
  Cookies.set('activeCampaigns', JSON.stringify(campaigns), { expires: 365 });
};

const useDetectCampaign = () => {
  const router = useRouter();
  const { tagManager } = useTagManager();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const campaignName = searchParams.get('utm_campaign') || searchParams.get('campaign');

    if (!campaignName) {
      return;
    }

    const activeCampaigns = getActiveCampaigns();
    const isUsedCampaign = activeCampaigns.some((el) => el === campaignName);

    if (!isUsedCampaign) {
      tagManager.dataLayer({
        dataLayer: {
          event: 'gtm.comeFromCampaign',
          url: window.location.href,
          // NOTE: do not change name of the field it can affect google analytics
          campaignName,
        },
      });

      setActiveCampaigns([...activeCampaigns, campaignName]);
    }
  }, [router, tagManager]);
};

export default useDetectCampaign;

import { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { toast } from 'react-toastify';

import Cookies from 'js-cookie';
import { useDisconnect } from 'wagmi';

import { SolanaProviderInterface } from '@shared/api/types/globalTools';
import { storageKeys } from '@shared/constants';
import displayWalletErrorToast from '@shared/helpers/displayWalletErrorToast';
import clearSavedConnectionDetails from '@shared/helpers/web3/clearSavedConnectionDetails';

import { useAuth } from '@store:web/components/providers/AuthProvider';

export const suppressAccountChangeLogout = () => {
  Cookies.set(storageKeys.PREVENT_LOG_OUT_ON_ACCOUNT_CHANGE, '1');
};

// TODO: rework logic
export default function useListenAccountChange() {
  const { isAuth, logout } = useAuth();
  const { disconnectAsync } = useDisconnect();

  useEffect(() => {
    if (!isDesktop || !isAuth) {
      return;
    }

    let provider: SolanaProviderInterface | Record<string, any> | undefined;
    let handleAccountChange: (newAccount: string[]) => Promise<void> | undefined;
    let eventName: string | undefined;

    const setupListener = async () => {
      try {
        const evm = await import('@shared/helpers/web3/evm').then((module) => module.default);
        provider = evm.getProvider();
        eventName = 'accountsChanged';

        if (!provider) {
          return;
        }

        handleAccountChange = async ([account]: string[]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const connectedAddress = localStorage.MSQconnectedAddress?.toLowerCase();

          if (connectedAddress !== account) {
            if (!Cookies.get(storageKeys.PREVENT_LOG_OUT_ON_ACCOUNT_CHANGE)) {
              clearSavedConnectionDetails();
              provider?.removeListener?.(eventName, handleAccountChange);
              const message = 'Disconnected due to account changed';
              toast(message, { toastId: message });
              await disconnectAsync();
              console.debug(' disconnect ');
              logout();
            }
          }

          Cookies.remove(storageKeys.PREVENT_LOG_OUT_ON_ACCOUNT_CHANGE);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        provider?.on?.(eventName, handleAccountChange);
      } catch (error) {
        displayWalletErrorToast({ error });
      }
    };

    setupListener();

    return () => {
      if (provider?.removeListener && handleAccountChange) {
        provider.removeListener?.(eventName, handleAccountChange);
      }
    };
  }, [isAuth, disconnectAsync, logout]);
}

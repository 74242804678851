'use client';

import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { useFeatureTogglesQuery } from '@query';

import { storeNavConfig } from '@store:web/utils/config/store';
import { useHideOnScroll } from '@store:web/utils/hooks';

import Navbar from '../Navbar/Navbar';
import NavbarLink from '../Navbar/NavbarLink';
import NavbarSqrTokenProvidersDropdown from '../Navbar/NavbarSqrTokenProvidersDropdown';
import TopNavbarAccountButton from './TopNavbarAccountButton';
import TopNavbarMenuButton from './TopNavbarMenuButton';

type TopNavbarProps = ComponentPropsWithoutRef<'nav'>;

const TopNavbar = ({ className, children, ...props }: TopNavbarProps) => {
  const { targetRef, isHidden, setIsHidden } = useHideOnScroll<HTMLDivElement>();

  const { data: featureToggle } = useFeatureTogglesQuery();

  return (
    <Navbar
      ref={targetRef}
      onFocus={() => setIsHidden(false)}
      className={classNames(
        className,
        'transition-all max-sm:px-2',
        isHidden
          ? '-translate-y-full ease-in opacity-0 animate-[sr-only_0s_0.3s_forwards]'
          : 'shadow-top-navbar ease-out',
      )}
      {...props}
    >
      <TopNavbarMenuButton />
      {featureToggle?.magicBoard && (
        <NavbarLink {...storeNavConfig.magicBoard} activeClassName="text-neutral-600" />
      )}
      <NavbarLink {...storeNavConfig.hotOffers} activeClassName="text-secondary" />
      <NavbarLink {...storeNavConfig.campaigns} />
      <NavbarSqrTokenProvidersDropdown />
      <TopNavbarAccountButton />
    </Navbar>
  );
};

export default TopNavbar;

import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';

import classNames from 'classnames';

type NavbarProps = ComponentPropsWithoutRef<'nav'>;

const Navbar = ({ className, ...props }: NavbarProps, ref: Ref<HTMLDivElement>) => {
  return (
    <nav
      ref={ref}
      className={classNames(
        'h-navbar w-full flex justify-between gap-1 sm:gap-4 px-6 py-2 bg-neutral-50 z-header',
        className,
      )}
      {...props}
    />
  );
};

export default forwardRef(Navbar);

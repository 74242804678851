import { ComponentType } from 'react';

import MultiColorCompass from '@uikit/icons/MultiColorCompass';
import MultiColorDownload from '@uikit/icons/MultiColorDownload';
import MultiColorGamepad from '@uikit/icons/MultiColorGamepad';
import MultiColorUpcoming from '@uikit/icons/MultiColorUpcoming';

import {
  NavCampaignsIcon,
  NavHotOffer,
  NavMagicBoardIcon,
  NavRewardPoolsIcon,
  NavSearch,
  NavTokenIcon,
} from '@store:web/components/layout/Navbar/NavbarItemIcon';

export const storeNavConfig = {
  discover: {
    label: 'Discover',
    href: '/',
    Icon: MultiColorCompass,
  },
  apps: {
    label: 'Apps',
    href: '/apps',
    Icon: MultiColorDownload,
  },
  games: {
    label: 'Games',
    href: '/games',
    Icon: MultiColorGamepad,
  },
  upcoming: {
    label: 'Upcoming',
    href: '/upcoming',
    Icon: MultiColorUpcoming,
  },
  hotOffers: {
    label: 'Hot Offers',
    href: '/hot-offers',
    Icon: NavHotOffer,
    tooltip: {
      title: 'hotOffer_headerIcon_title',
      content: 'hotOffer_headerIcon_description',
    },
  },
  campaigns: {
    label: 'Campaigns',
    href: 'https://launchpad.magicsquare.io/campaigns',
    Icon: NavCampaignsIcon,
    tooltip: {
      title: 'campaigns_headerIcon_title',
      content: 'campaigns_headerIcon_description',
    },
    isExternal: true,
  },
  magicBoard: {
    label: 'Magic Board',
    href: '/magic-board',
    Icon: NavMagicBoardIcon,
    tooltip: {
      title: 'magicBoard_headerIcon_title',
      content: 'magicBoard_headerIcon_description',
    },
  },
  search: {
    label: 'Search',
    href: '/search',
    Icon: NavSearch,
  },
  getSQR: {
    label: 'Get $SQR Token',
    isExternal: true,
    href: 'url.getSqrToken', //'https://partner.bybit.com/b/sqrsplash',
    shouldTranslateHref: true,
    Icon: NavTokenIcon,
  },
  rewardPools: {
    label: 'Reward Pools',
    href: '/reward-pools',
    Icon: NavRewardPoolsIcon,
    tooltip: {
      title: 'rewardPools_headerIcon_title',
      content: 'rewardPools_headerIcon_description',
    },
  },
} satisfies Record<
  string,
  {
    label: string;
    href: string;
    Icon: ComponentType;
    isExternal?: boolean;
    shouldTranslateHref?: boolean;
    tooltip?: {
      title: string;
      content: string;
    };
  }
>;

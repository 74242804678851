'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { keepPreviousData } from '@tanstack/react-query';

import classNames from 'classnames';
import { useDebounce } from 'usehooks-ts';

import ImageProxy from '@next-image-proxy';

import { SearchBar, SearchBarOption } from '@uikit';

import { useStoreAppsPreview } from '@query';

import { AppPreview } from '@api';

import Search from '@uikit/icons/Search';

import displayErrorToast from '@shared/helpers/displayErrorToast';

const HeaderSearchBar = ({ className }: { className?: string }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const searchParamsFind = searchParams.get('find') || '';
  const [search, setSearch] = useState(searchParamsFind);
  const [isFocus, setIsFocus] = useState(false);
  const searchBarInputRef = useRef<HTMLInputElement>(null);
  const debouncedSearch = useDebounce(search, 300);
  const {
    data,
    isPending: isLoading,
    isFetching,
    error,
  } = useStoreAppsPreview(
    { search: debouncedSearch },
    {
      enabled: isFocus && !!search && !!debouncedSearch,
      placeholderData: keepPreviousData,
    },
  );
  const appsPreview: AppPreview[] = search && data?.data ? data.data : [];
  const totalApps = data?.total_apps || 0;

  const openSearchPage = (value?: string) => {
    const params = value ? new URLSearchParams({ find: value }).toString() : '';
    router.push(`/search?${params}`);
  };

  const openAppPage = (appId: string) => {
    setSearch('');
    router.push(`/app/${appId}`);
  };

  const handleChange = (value: string) => {
    setSearch(value);
  };

  const handleSearch = () => {
    openSearchPage(search);

    // blur to hide options dropdown
    searchBarInputRef.current?.blur();
  };

  useLayoutEffect(() => {
    setSearch(searchParamsFind);
  }, [searchParamsFind]);

  useEffect(() => {
    const handleScroll = () => {
      searchBarInputRef.current?.blur();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (error) {
    displayErrorToast({ error });
  }

  return (
    <SearchBar
      className={classNames(className)}
      value={search}
      placeholder="Apps, Games and more..."
      isOpen={!!search && !isLoading}
      onChange={handleChange}
      onSearch={handleSearch}
      isLoading={isFetching}
      inputRef={searchBarInputRef}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      onReset={() => {
        if (pathname === '/search') {
          openSearchPage('');
        }
      }}
    >
      {!isLoading &&
        appsPreview.slice(0, 9)?.map((appPreview) => {
          return (
            <SearchBarOption
              className="flex items-center gap-2.5"
              key={appPreview.id}
              style={{ cursor: 'pointer' }}
              onMouseDown={(e) => {
                e.currentTarget.blur();
                openAppPage(appPreview.app_id);
              }}
            >
              <ImageProxy
                alt="App's icon"
                className="w-4 h-4 flex-shrink-0 rounded-full"
                src={appPreview.app_icon}
                width={16}
                height={16}
              />
              {appPreview.app_name}
            </SearchBarOption>
          );
        })}

      {!isLoading && !appsPreview.length && (
        <SearchBarOption Component="p" className="hover:bg-neutral-50 pl-[2.625rem]">
          No results
        </SearchBarOption>
      )}

      {!isLoading && (
        <SearchBarOption
          className="text-primary-600"
          onMouseDown={(e) => {
            e.currentTarget.blur();
            openSearchPage(search);
          }}
        >
          <Search className="icon-xs mr-2.5" />
          See all results {totalApps ? `(${totalApps})` : ''}
        </SearchBarOption>
      )}
    </SearchBar>
  );
};

export default HeaderSearchBar;

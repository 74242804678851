import { usePathname } from 'next/navigation';

import { MouseEvent } from 'react';

import classNames from 'classnames';

import { Dropdown, DropdownItem } from '@uikit';

import { AngleDown } from '@uikit/icons/AngleDown';
import { MultiColorCompass } from '@uikit/icons/MultiColorCompass';

import NextLink from '@shared/common/components/NextLink';

import { storeNavConfig } from '@store:web/utils/config/store';

import NavbarItem from './NavbarItem';
import NavbarItemIcon from './NavbarItemIcon';

const navInOrder = [
  storeNavConfig.discover,
  storeNavConfig.apps,
  storeNavConfig.games,
  storeNavConfig.upcoming,
];

const NavbarStoreNavDropdown = ({ className }: { className?: string }) => {
  const pathname = usePathname();
  const isDropdownActive = navInOrder.some((navItem) => navItem.href === pathname);

  return (
    <div className={className}>
      <Dropdown
        trigger={({ triggerProps: { onClick, ...triggerProps }, isOpen }) => {
          const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            if (onClick && typeof onClick === 'function') {
              onClick(event);
            }
          };

          return (
            <NavbarItem
              Component="button"
              // NOTE: this is a quick fix to hide focus, because it appears on scroll when dropdown hides
              // TODO: find better way to handle above case
              className="focus:outline-none"
              isActive={isDropdownActive}
              label={
                <span className="inline-flex justify-center w-full items-center">
                  Store
                  <AngleDown className={classNames('icon-xs', isOpen && 'rotate-180')} />
                </span>
              }
              onClick={handleClick}
              {...triggerProps}
            >
              <NavbarItemIcon
                isActive={isDropdownActive}
                Icon={MultiColorCompass}
                className="text-primary"
              />
            </NavbarItem>
          );
        }}
      >
        {navInOrder.map((navItem) => {
          return (
            <DropdownItem
              key={navItem.href}
              Component={NextLink}
              label={navItem.label}
              href={navItem.href}
              isActive={pathname === navItem.href}
            />
          );
        })}
      </Dropdown>
    </div>
  );
};

export default NavbarStoreNavDropdown;

'use client';

import { ReactNode, useEffect, useMemo } from 'react';

import { io, Socket } from 'socket.io-client';

import { createSafeContext, useSafeContext } from '@shared/helpers/context';

import { GLOBAL_CONFIG } from '@store:web/utils/config/global';

import { useAuth } from './AuthProvider';

interface SocketProviderProps {
  children?: ReactNode;
}

interface ContextValue {
  socket?: Socket;
}

const Context = createSafeContext<ContextValue>();

const SocketProvider = ({ children }: SocketProviderProps) => {
  const value = useMemo(
    () => ({
      socket: io(GLOBAL_CONFIG.socketHost, {
        path: '/ws/socket.io',
        autoConnect: false,
      }),
    }),
    [],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default SocketProvider;

export const useSocket = () => {
  const { socket } = useSafeContext(Context);
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.meta?.jti || !socket || socket.connected) {
      return;
    }

    socket.auth = {
      jti: user.meta.jti,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.sock = socket;
    socket.connect();

    // Test logs
    socket.on('connect_error', (error) => {
      console.debug('connect_error', error);
    });
    socket.on('error', (error) => {
      console.debug('error', error);
    });
    socket.on('*', function (event, data) {
      console.debug('*', { event, data });
    });
    socket.on('pong', (data) => {
      console.debug('pong', data);
    });

    // Global events are bound against socket
    socket.on('connect_failed', () => {
      console.debug('socket: Connection Failed');
    });
    socket.on('connect', () => {
      console.debug('socket: Connected');
    });

    socket.on('disconnect', () => {
      console.debug('socket: Disconnected');
    });

    return () => {
      socket.disconnect();
    };
  }, [user, socket]);

  return { socket };
};

import { useFeatureTogglesQuery } from '@query';

import { VisaCard } from '@uikit/icons/VisaCard';

import BanxaModal from '@shared/common/components/modals/BanxaModal';
import { useBanxaModal } from '@shared/common/providers/GlobalModalsProvider';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const BanxaButton = () => {
  const { data: featureToggles = {} } = useFeatureTogglesQuery();
  const modal = useBanxaModal();

  const { userEvmWallet } = useAuth();

  const handleClick = () => {
    modal.open({
      walletAddress: userEvmWallet?.attributes.address,
    });
  };

  if (!featureToggles.banxaSqrTokenSource) {
    return null;
  }

  return (
    <>
      <button
        className="menu-list-item flex gap-2 items-center outline-none"
        onClick={handleClick}
        type="button"
      >
        <VisaCard className={'icon'} />
        Buy with Card
      </button>

      <BanxaModal />
    </>
  );
};

export default BanxaButton;

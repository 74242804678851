'use client';
import { ComponentPropsWithoutRef, FC } from 'react';

import classNames from 'classnames';
import { useIsClient } from 'usehooks-ts';

import { SocialNetworks } from '@shared/common/components/SocialNetworks';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

export const staticUrls = {
  privacyPolicy: 'https://docs.magic.store/documents/legal-documents/privacy-policy',
  termsAndConditions:
    'https://docs.magic.store/documents/legal-documents/magic-store-terms-and-conditions',
  listYourApp: 'https://magicsquare.io/submit-your-app/',
  applyForGrant: 'https://forms.gle/5TaH6iUMsQfGUVVR9',
};

export interface PolicyAndTermsProps extends ComponentPropsWithoutRef<'div'> {
  shortVersion?: boolean;
}

const PolicyAndTerms: FC<PolicyAndTermsProps> = ({ shortVersion, className, ...props }) => {
  const featureToggles = useFeatureToggles();
  const anchorClassname =
    'text-neutral-500 cursor-pointer no-underline hover:underline active:decoration-underline text-[8px] md:text-base';
  const shortAnchorClassname =
    'text-primary-700 cursor-pointer no-underline hover:underline active:decoration-underline';
  const isClient = useIsClient();
  const isVersionShown = isClient && localStorage.getItem('presentTag') === '1';

  if (shortVersion) {
    return (
      <div
        className={classNames(
          'text-xs font-medium leading-[1.125rem] mt-0 w-auto text-neutral-600',
          'w-[260px] max-w-full mt-2',
          className,
        )}
        {...props}
      >
        {"By continuing you agree to Magic Square's "}
        <a
          className={shortAnchorClassname}
          href={staticUrls.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          className={shortAnchorClassname}
          href={staticUrls.termsAndConditions}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'flex flex-col max-lg:items-center store-container pb-10 gap-2',
        className,
      )}
    >
      <div
        className={classNames(
          'flex flex-col lg:flex-row justify-center lg:justify-between gap-4',
          className,
        )}
      >
        <div className={classNames('flex gap-2 justify-center lg:justify-start')}>
          <a
            className={anchorClassname}
            href={staticUrls.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <span className={anchorClassname}>|</span>
          <a
            className={anchorClassname}
            href={staticUrls.termsAndConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
          <span className={anchorClassname}>|</span>
          <a
            className={classNames(anchorClassname, 'text-secondary-700')}
            href={staticUrls.listYourApp}
            target="_blank"
            rel="noopener noreferrer"
          >
            List Your Project
          </a>
          <span className={anchorClassname}>|</span>
          <a
            className={anchorClassname}
            href={staticUrls.applyForGrant}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply for a Grant
          </a>
        </div>
        <div
          className={classNames('justify-center lg:justify-end lg:gap-4 gap-2', {
            'hidden lg:flex': featureToggles.mobileHideSocialFooter,
            flex: !featureToggles.mobileHideSocialFooter,
          })}
        >
          <SocialNetworks />
        </div>
      </div>

      {isVersionShown && process.env.NEXT_PUBLIC_STORE_VERSION && (
        <span className="text-3xs text-neutral-400 md:text-base">
          {process.env.NEXT_PUBLIC_STORE_VERSION}
        </span>
      )}
    </div>
  );
};

export default PolicyAndTerms;

'use client';

import { ComponentPropsWithoutRef, Suspense } from 'react';

import classNames from 'classnames';

import { SearchBar } from '@uikit';

import { useFeatureTogglesQuery } from '@query';

import { storeNavConfig } from '@store:web/utils/config/store';
import { useHideOnScroll } from '@store:web/utils/hooks/useHideOnScroll';

import NavbarLink from '../Navbar/NavbarLink';
import NavbarSqrTokenProvidersDropdown from '../Navbar/NavbarSqrTokenProvidersDropdown';
import NavbarStoreNavDropdown from '../Navbar/NavbarStoreNavDropdown';
import HeaderSearchBar from './HeaderSearchBar';

type HeaderProps = ComponentPropsWithoutRef<'div'>;

const Header = ({ className, children, ...props }: HeaderProps) => {
  const { targetRef, isHidden, setIsHidden } = useHideOnScroll<HTMLDivElement>();
  const { data: featureToggle } = useFeatureTogglesQuery();

  return (
    <div
      onFocus={() => setIsHidden(false)}
      ref={targetRef}
      className={classNames(
        'flex items-center h-header bg-neutral-50 transition-all duration-300',
        isHidden
          ? '-translate-y-full ease-in opacity-0 animate-[sr-only_0s_0.3s_forwards]'
          : 'shadow-header ease-out',
        className,
      )}
      {...props}
    >
      <nav className="flex flex-col w-full store-container-px !px-10 gap-2.5">
        <div className="flex items-center relative justify-between w-full">
          <NavbarLink className="max-xl:hidden" {...storeNavConfig.discover} />

          <NavbarLink className="max-xl:hidden" {...storeNavConfig.apps} />

          <NavbarLink className="max-xl:hidden" {...storeNavConfig.games} />

          <NavbarLink className="max-xl:hidden" {...storeNavConfig.upcoming} />

          <NavbarStoreNavDropdown className="xl:hidden" />

          {featureToggle?.rewardPools && <NavbarLink {...storeNavConfig.rewardPools} />}

          {featureToggle?.magicBoard && (
            <NavbarLink {...storeNavConfig.magicBoard} activeClassName="text-neutral-600" />
          )}

          <NavbarLink {...storeNavConfig.hotOffers} activeClassName="text-secondary-700" />

          <NavbarLink {...storeNavConfig.campaigns} activeClassName="text-secondary-700" />

          <NavbarSqrTokenProvidersDropdown />
        </div>
        <Suspense fallback={<SearchBar placeholder="Apps, Games and more..." />}>
          <HeaderSearchBar className="flex-1" />
        </Suspense>
      </nav>
    </div>
  );
};

export default Header;

import { useGetOneInchTermsQuery } from '@query';

import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { useAuth } from '@store:web/components/providers/AuthProvider';

export const useGetOpenSwapperTerms = () => {
  const featureToggles = useFeatureToggles();

  // Linked to NOTE #g21b78g23h788
  const { data: terms, isFetching: isLoadingTerms } = useGetOneInchTermsQuery(-9999, {
    enabled: featureToggles.swapper,
  });

  const showSwapper = Boolean(
    featureToggles.swapperLinks && featureToggles.swapper && !isLoadingTerms && terms?.meta?.result,
  );

  return {
    isLoadingTerms,
    showSwapper,
    showSwapperLinks: showSwapper,
  };
};

export const useGetAuthSwapperTerms = () => {
  const { isAuth, isLoading, user } = useAuth();
  const featureToggles = useFeatureToggles();

  // Linked to NOTE #g21b78g23h788
  const { data: terms, isFetching: isLoadingTerms } = useGetOneInchTermsQuery(user?.id || 0, {
    enabled: isAuth && featureToggles.swapper,
  });

  const showSwapper = Boolean(
    !isLoading && !isLoadingTerms && isAuth && featureToggles.swapper && terms?.meta?.result,
  );

  return {
    isLoadingTerms,
    showSwapper,
    showSwapperLinks: showSwapper && featureToggles.swapperLinks,
  };
};

const isOnlyForAuthedUsers = false;

export default isOnlyForAuthedUsers ? useGetAuthSwapperTerms : useGetOpenSwapperTerms;

import { Avatar as AvatarIcon } from '@uikit/icons/Avatar';

import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';

import Avatar from '@store:web/components/common/Avatar/Avatar';
import { useAuth } from '@store:web/components/providers/AuthProvider';
import { useProfileMenu } from '@store:web/components/providers/ProfileMenuProvider/ProfileMenuProvider';

import NavbarItem from '../Navbar/NavbarItem';

const TopNavbarAccountButton = () => {
  const { isAuth, user } = useAuth();
  const accountMenu = useProfileMenu();
  const loginModal = useAuthModal();

  return (
    <NavbarItem
      Component="button"
      label="Account"
      onClick={() => {
        if (isAuth) {
          accountMenu.open();
        } else {
          loginModal.open();
        }
      }}
    >
      {isAuth ? (
        <Avatar
          isLink={false}
          userInfo={{
            magicId: user.attributes.name,
            displayName: user.attributes.displayedName,
            membership: {
              tier: user?.membershipTier?.attributes?.key,
            },
          }}
          src={user.attributes.icon}
          sizeInPX={32}
        />
      ) : (
        <AvatarIcon className="icon bg-primary-100 rounded-full text-primary-700" />
      )}
    </NavbarItem>
  );
};

export default TopNavbarAccountButton;

'use client';

import dynamic from 'next/dynamic';

import { useModalController } from '@uikit';

import { Drawer, DrawerHeader } from '@uikit/components/Drawer';

import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import useGetSwapperTerms from '@store:web/components/pages/MagicSwap/hooks/useGetSwapperTerms';
import ReferAFriendBanner from '@store:web/components/pages/ReferAFriend/ReferAFriendBanner';
import { useAuth } from '@store:web/components/providers/AuthProvider';

import { sidebarNavItems } from '../Sidebar/Sidebar';
import DrawerMenu from '../TopNavbar/DrawerMenu';
import DrawerMenuItem from '../TopNavbar/DrawerMenuItem';
import DrawerMenuLink from '../TopNavbar/DrawerMenuLink';

const KarmaModal = dynamic(() => import('../KarmaModal/KarmaModal'));
const NotificationModal = dynamic(() => import('../NotificationModal/NotificationModal'));
const SupportModal = dynamic(
  () => import('@store:web/components/common/SupportModal/SupportModal'),
);

interface StoreNavMenuProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const StoreNavMenu = ({ isOpen, onClose }: StoreNavMenuProps) => {
  const { isAuth, isLoading } = useAuth();
  const modalKarma = useModalController();
  const modalNotification = useModalController();
  const supportModal = useModalController();
  const authModal = useAuthModal();
  const featureToggles = useFeatureToggles();
  const { showSwapperLinks } = useGetSwapperTerms();

  const handleProtectedNavItemClick = (event: any) => {
    if (isLoading) {
      event.preventDefault();
    }

    if (!isAuth) {
      event.preventDefault();
      authModal.open();
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} className="bg-neutral-600 text-neutral-50">
        <DrawerHeader title="Menu" />

        <DrawerMenu className="divide-y divide-neutral-50/20 border-y border-neutral-50/20">
          <DrawerMenuLink {...sidebarNavItems.magicId} onClick={handleProtectedNavItemClick} />

          <DrawerMenuLink {...sidebarNavItems.store} />

          <DrawerMenuLink
            iconClassName="opacity-60"
            {...sidebarNavItems.boost}
            data-onboarding-store-nav-menu-item="magic-boost"
            onClick={handleProtectedNavItemClick}
          />

          {featureToggles.perks && (
            <DrawerMenuLink iconClassName="opacity-60" {...sidebarNavItems.perks} />
          )}

          {featureToggles.staking && (
            <DrawerMenuLink {...sidebarNavItems.staking} target="_blank" rel="noreferrer " />
          )}

          {showSwapperLinks && <DrawerMenuLink {...sidebarNavItems.swap} />}

          <DrawerMenuLink {...sidebarNavItems.otc} target="_blank" rel="noreferrer " />

          {featureToggles.launchpad && (
            <DrawerMenuLink {...sidebarNavItems.launchpad} target="_blank" rel="noreferrer " />
          )}

          {featureToggles.rewardPools && <DrawerMenuLink {...sidebarNavItems.rewardPools} />}

          {isAuth && (
            <DrawerMenuItem
              iconClassName="opacity-60"
              Component="button"
              {...sidebarNavItems.notifications}
              onClick={modalNotification.open}
            />
          )}

          {isAuth && (
            <DrawerMenuItem
              iconClassName="opacity-60"
              Component="div"
              {...sidebarNavItems.karma}
              onClick={modalKarma.open}
            />
          )}

          <DrawerMenuLink
            data-onboarding-store-nav-menu-item="docs"
            iconClassName="opacity-60"
            {...sidebarNavItems.docs}
          />

          <DrawerMenuItem
            iconClassName="opacity-60 h-[26px]"
            Component="button"
            data-onboarding-store-nav-menu-item="support"
            {...sidebarNavItems.support}
            onClick={supportModal.open}
          />
        </DrawerMenu>

        <ReferAFriendBanner onClick={onClose} className="p-7" />
      </Drawer>
      {isAuth && (
        <>
          {modalNotification.isOpen && <NotificationModal {...modalNotification.props} />}
          {modalKarma.isOpen && <KarmaModal {...modalKarma.props} />}
        </>
      )}

      {supportModal.isOpen && <SupportModal {...supportModal.props} />}
    </>
  );
};

export default StoreNavMenu;

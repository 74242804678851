import { useEffect } from 'react';

import Cookies from 'js-cookie';

import { pingUserAction } from '@api';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const getNextDateUTC = () => {
  const date = new Date();
  const nextDay = new Date(date.setUTCDate(date.getUTCDate() + 1)).setUTCHours(0, 0, 0);
  return new Date(nextDay);
};

const TIME_TO_TACK_USER_ACTIVITY = 1000 * 60 * 2;

export default function usePingUserAction() {
  const { isAuth, user } = useAuth();

  const safeParse = (patient?: string) => {
    try {
      return [...(JSON.parse(patient || '') || [])];
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const idsHasPing: any[] = safeParse(Cookies.get('idsHasPing'));
      const hasPingAlready = idsHasPing?.some(
        (el) => parseInt(el, 10) === parseInt(String(user?.id || 0), 10),
      );

      if (isAuth && user?.id && !hasPingAlready) {
        pingUserAction({ json: { data: ['2MINUTES'] } });
        Cookies.set('idsHasPing', JSON.stringify([...idsHasPing, user?.id]), {
          expires: getNextDateUTC(),
        });
      }
    }, TIME_TO_TACK_USER_ACTIVITY);

    return () => clearTimeout(timer);
  }, [isAuth, user?.id]);
}

'use client';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useEffect, useState } from 'react';

import { useEmailLoginError } from '@shared/common/providers/GlobalModalsProvider';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const useHandleEmailLoginError = () => {
  const { open: openLinkExpiredModal } = useEmailLoginError();
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { isAuth, isLoading } = useAuth();
  // using state, because router.replace doesn't change route immediately causing infinite useEffect calls
  const [isExpired, setIsExpired] = useState(() => searchParams.get('expired') === 'join');

  useEffect(() => {
    if (!isExpired || isLoading) {
      return;
    }

    const params = new URLSearchParams(searchParams);
    params.delete('expired');
    router.replace(`${pathname}?${params.toString()}`);

    setIsExpired(false);

    if (!isAuth) {
      openLinkExpiredModal();
    }
  }, [isLoading, isAuth, openLinkExpiredModal, pathname, router, searchParams, isExpired]);
};

export default useHandleEmailLoginError;

'use client';

import { ReactNode, useId, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import { useNotificationBanner } from './NotificationBannerProvider';

interface NotificationBannerPortalProps {
  children: ReactNode;
  priority?: number;
}

/**
 * Used for rendering content of the banner in all places where NotificationBannerContainer is used
 * Can be used multiple times
 * If used multiple times we can specify priority prop for controlling which banner should be shown
 *
 * @example
 *
 * // priority prop is specified to render this banner instead of other banners that have lower priority
 * const NestedBanner = () => {
 *   return (
 *     <NotificationBannerProvider priority={1}>
 *        <div className="notification-banner">
 *          Nested banner
 *        </div>
 *     </NotificationBannerProvider>
 *   )
 * }
 *
 * // Here we rendered 2 NotificationBannerProvider one on top level and one nested in NestedBanner
 * // Because only one banner can be display at a time the first one will be rendered
 * // To render nested banner we specified priority prop equal to 1 so it will have more priority and rendered instead
 * const AppContent = () => {
 *   return (
 *      <>
 *        <NotificationBannerProvider>
 *          <div className="notification-banner">
 *            Banner
 *          </div>
 *        </NotificationBannerProvider>
 *
 *        <NestedBanner />
 *      </>
 *   )
 * }
 *
 * const App = () => {
 *   return (
 *     <NotificationBannerProvider>
 *       <NotificationBannerContent />
 *       <AppContent />
 *     </NotificationBannerProvider>
 *   )
 * }
 */
const NotificationBannerPortal = ({ children, priority = 0 }: NotificationBannerPortalProps) => {
  const id = useId();
  const { setBanner, removeBanner, activeBanner } = useNotificationBanner();
  const containers =
    typeof document !== 'undefined'
      ? document.querySelectorAll('[data-notification-banner-container]')
      : undefined;

  useLayoutEffect(() => {
    setBanner({
      id,
      priority,
    });

    return () => {
      removeBanner(id);
    };
  }, [id, priority, setBanner, removeBanner]);

  if (!containers?.length || !activeBanner || activeBanner.id !== id) {
    return null;
  }

  return Array.from(containers, (container) => createPortal(children, container));
};

export default NotificationBannerPortal;

import { usePathname, useRouter } from 'next/navigation';

import { useEffect } from 'react';

import { useTagManager } from '@shared/common/providers/GoogleTagManagerProvider';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const useTrackEmailAuth = () => {
  const pathname = usePathname();
  const router = useRouter();
  const { user, isLoading } = useAuth();
  const { tagManager, triggerAuthEvent } = useTagManager();

  useEffect(() => {
    if (isLoading || !user) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const authEmailSearchParam = searchParams.get('authEmail');

    if (!authEmailSearchParam) {
      return;
    }

    if (authEmailSearchParam !== 'new' && authEmailSearchParam !== 'old') {
      return;
    }

    triggerAuthEvent(user.id, {
      isNewUser: authEmailSearchParam === 'new',
    });

    searchParams.delete('authEmail');

    router.replace(`${pathname}${searchParams.size > 0 ? `?${searchParams}` : ''}`);
  }, [tagManager, router, user, isLoading, triggerAuthEvent, pathname]);
};

export default useTrackEmailAuth;

import { useEffect, useRef } from 'react';

import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const useShowAuthModalOnLogout = () => {
  const { open: openAuthModal } = useAuthModal();
  const { isAuth } = useAuth();
  const previousIsAuthRef = useRef(isAuth);

  useEffect(() => {
    if (!isAuth && previousIsAuthRef.current) {
      openAuthModal();
    }

    previousIsAuthRef.current = isAuth;
  }, [isAuth, openAuthModal]);
};

export default useShowAuthModalOnLogout;

'use client';

import Link from 'next/link';
import { useTranslations } from 'next-intl';

import { forwardRef, Ref } from 'react';

import { useIsPathnameLinkHref } from '@store:web/utils/hooks/usePathnameMatchUrl';

import NavbarItem, { NavbarItemProps } from './NavbarItem';

type NavbarLinkProps = NavbarItemProps<typeof Link> & {
  isExternal?: boolean;
  shouldTranslateHref?: boolean;
};

const NavbarLink = (
  { isActive, shouldTranslateHref, isExternal, href, children, ...props }: NavbarLinkProps,
  ref: Ref<HTMLAnchorElement | null>,
) => {
  const t = useTranslations();
  const active = useIsPathnameLinkHref(href);
  const externalLinkProps = isExternal
    ? {
        target: '_blank',
        rel: 'noreferrer',
      }
    : {};

  const actualHref = shouldTranslateHref ? t(href) : href;

  return (
    <NavbarItem
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref={ref}
      href={actualHref}
      isActive={active}
      {...externalLinkProps}
      {...props}
    />
  );
};

export default forwardRef(NavbarLink);

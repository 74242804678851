import { Drawer, DrawerHeader, DrawerMenuItem } from '@uikit/components/Drawer';

import MembershipTierBanner from '@store:web/components/common/MembershipTierBanner/MembershipTierBanner';
import { useAuth } from '@store:web/components/providers/AuthProvider';

import { useProfileNavItems } from '../ProfileLayout/ProfileLayoutSidebarNav';
import DrawerMenu from '../TopNavbar/DrawerMenu';
import DrawerMenuLink from '../TopNavbar/DrawerMenuLink';
import DrawerSubMenu from '../TopNavbar/DrawerSubMenu';

interface ProfileMenuProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const ProfileMenu = ({ isOpen, onClose }: ProfileMenuProps) => {
  const { user, logout } = useAuth();
  const profileNavItems = useProfileNavItems();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerHeader className="max-sm:pl-4" title="Account" />

      <MembershipTierBanner tier={user?.membershipTier?.attributes?.key} />

      <DrawerMenu className="divide-y divide-neutral-100 border-y border-neutral-100">
        {profileNavItems.map((item) => {
          if (item.hiddenInMenu) {
            return null;
          }

          if ('items' in item) {
            return <DrawerSubMenu key={item.id} items={item.items} label={item.label} />;
          }

          return (
            <DrawerMenuLink
              data-onboarding-profile-menu-item={item.id}
              className="max-sm:pl-4"
              key={item.id}
              href={item.href}
              label={item.label}
            />
          );
        })}

        <DrawerMenuItem className="max-sm:pl-4" Component="button" onClick={() => logout()}>
          Logout
        </DrawerMenuItem>
      </DrawerMenu>
    </Drawer>
  );
};

export default ProfileMenu;

'use client';

import Menu from '@uikit/icons/Menu';

import { useStoreNavMenu } from '@store:web/components/providers/StoreNavMenuProvider/StoreNavMenuProvider';

import NavbarItem from '../Navbar/NavbarItem';

const TopNavbarAccountButton = () => {
  const storeNavMenu = useStoreNavMenu();

  return (
    <NavbarItem Component="button" label="Menu" onClick={storeNavMenu.open}>
      <Menu className="icon-lg bg-primary-100 rounded-full text-primary-700" />
    </NavbarItem>
  );
};

export default TopNavbarAccountButton;

'use client';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import Script from 'next/script';

import { ReactNode } from 'react';

import classNames from 'classnames';
import { useIsClient } from 'usehooks-ts';

import { captchaContainerName } from '@shared/helpers/captcha';

import PolicyAndTerms from '@store:web/components/common/PolicyAndTerms/PolicyAndTerms';
import Sidebar from '@store:web/components/layout/Sidebar/Sidebar';
const NotificationBannerOutlet = dynamic(
  () =>
    import('@store:web/components/providers/NotificationBannerProvider/NotificationBannerOutlet'),
  { ssr: false },
);
const NotificationBannerPortal = dynamic(
  () =>
    import('@store:web/components/providers/NotificationBannerProvider/NotificationBannerPortal'),
  { ssr: false },
);

import BottomNavbar from '../BottomNavbar';
import ClientSideHooks from '../ClientSideHooks/ClientSideHooks';
import Header from '../Header';
import TopNavbar from '../TopNavbar';
import { useGetBanner } from './hooks';

interface MainLayoutProps {
  children?: ReactNode;
}

export const NotificationBanner = () => {
  const pathname = usePathname();
  const { banner, className } = useGetBanner();

  const isHidden = ['/app/', '/developer/'].some((path) => pathname.startsWith(path));

  if (isHidden || !banner) {
    return null;
  }

  return (
    <NotificationBannerPortal>
      <div className={classNames('notification-banner', className)}>
        <div dangerouslySetInnerHTML={{ __html: banner }} />
      </div>
    </NotificationBannerPortal>
  );
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const isClient = useIsClient();
  const pathname = usePathname();
  const shouldHideHeaderOnLargeDevices = [
    '/profile',
    '/refer-a-friend',
    '/magic-boost',
    '/magic-swap',
    '/spaces',
  ].some((path) => pathname.startsWith(path));
  const isMembershipSummaryPage = pathname === '/profile/membership/summary';
  const isProfilePage = pathname.startsWith('/profile');

  const showTerms = !shouldHideHeaderOnLargeDevices;

  return (
    <>
      <div id={captchaContainerName} className="hidden" />

      <NotificationBanner />

      <Sidebar className="max-lg:hidden" />

      <header className={classNames('fixed top-0 w-full left-0 z-header lg:pl-sidebar')}>
        <NotificationBannerOutlet
          className={classNames({
            'lg:pl-profile-sidebar': isProfilePage && !isMembershipSummaryPage,
          })}
        />

        <TopNavbar className="lg:hidden" />

        <Header
          className={classNames('max-lg:hidden', {
            'lg:hidden': shouldHideHeaderOnLargeDevices,
          })}
        />
      </header>

      <BottomNavbar className="fixed bottom-0 left-0 z-header lg:hidden" />

      {/* NOTE: render it second time to occupy space for offset */}
      <NotificationBannerOutlet
        aria-hidden="true"
        className={classNames('invisible shrink-0', {
          'lg:pl-sidebar-with-profile-sidebar': isProfilePage && !isMembershipSummaryPage,
          'lg:pl-sidebar': !isProfilePage || isMembershipSummaryPage,
        })}
      />

      {/* NOTE: DO NOT SET OVERFLOW ON THIS ELEMENT IT CAN PRODUCT UNEXPECTED BUGS */}
      <div
        className={classNames(
          'w-full flex-auto flex flex-col lg:pl-sidebar max-lg:pb-navbar',
          'pt-navbar',
          {
            'lg:pt-0': shouldHideHeaderOnLargeDevices,
            'lg:pt-header': !shouldHideHeaderOnLargeDevices,
          },
        )}
      >
        {children}

        {showTerms && <PolicyAndTerms className="store-container mt-auto" />}
      </div>

      {isClient && <ClientSideHooks />}
      {/* NOTE: not sure why we need this script */}
      <Script src="https://accounts.google.com/gsi/client" async defer strategy="lazyOnload" />
      <Script src="https://servedbyadbutler.com/app.js" async defer />
    </>
  );
};

export default MainLayout;

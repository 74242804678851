import { ReactNode, useMemo } from 'react';
import type { Step } from 'react-joyride';

import NextLink from '@shared/common/components/NextLink';

import useUserWalletStatusFlags from '@store:web/utils/hooks/useWeb3/useUserWalletStatusFlags';

import { useAuth } from '../AuthProvider';
import { useProfileMenu } from '../ProfileMenuProvider/ProfileMenuProvider';
import { useStoreNavMenu } from '../StoreNavMenuProvider/StoreNavMenuProvider';

export const STEP_NAME = {
  CONNECT_WALLET: 'CONNECT_WALLET',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  SET_USERNAME_AND_DISPLAYNAME: 'SET_USERNAME_AND_DISPLAYNAME',
  CONNECT_WALLET_OR_VERIFY_EMAIL: 'CONNECT_WALLET_OR_VERIFY_EMAIL',
  VERIFY_GITCOIN: 'VERIFY_GITCOIN',
  VISIT_HOT_OFFERS_PAGE: 'VISIT_HOT_OFFERS_PAGE',
  VISIT_KARMA: 'VISIT_KARMA',
  VISIT_KARMA_OVERVIEW: 'VISIT_KARMA_OVERVIEW',
  VISIT_KARMA_LEADERBOARD: 'VISIT_KARMA_LEADERBOARD',
  VISIT_KARMA_HISTORY: 'VISIT_KARMA_HISTORY',
  REFER_A_FRIEND: 'REFER_A_FRIEND',
  EXPLORE_APP: 'EXPLORE_APP',
  PARTICIPATE_IN_VALIDATION: 'PARTICIPATE_IN_VALIDATION',
  VISIT_MAGIC_BOOST: 'VISIT_MAGIC_BOOST',
  VISIT_GITBOOK: 'VISIT_GITBOOK',
  SUPPORT: 'SUPPORT',
  CONGRATS: 'CONGRATS',
} as const;

export type StepNameKey = keyof typeof STEP_NAME;

export type StepNameValue = (typeof STEP_NAME)[StepNameKey];

export type OnboardingStep = {
  name: StepNameValue;
  header?: {
    content: ReactNode;
  };
  step: Step;
  isCompleted?: boolean;
  pathname?: string;
  exactPathname?: boolean;
  lockScroll?: boolean;
  isEnabled?: boolean;
  onBeforeStep?: () => void;
  onAfterStep?: () => void;
};

const useOnboardingSteps = () => {
  const { user } = useAuth();
  const { isEMVWalletConnected } = useUserWalletStatusFlags();
  const isSelfEmailVerified = !!user?.attributes.email;
  const isSelfEVMWalletConnected = isEMVWalletConnected;
  const isSellUsernameSet = !!user?.attributes.name;
  const isSelfDisplaynameSet = !!user?.attributes.displayedName;
  const profileMenu = useProfileMenu();
  const storeNavMenu = useStoreNavMenu();

  return useMemo(() => {
    const memoizedSteps: OnboardingStep[] = [
      {
        name: STEP_NAME.CONNECT_WALLET_OR_VERIFY_EMAIL,
        isCompleted: isSelfEmailVerified && isSelfEVMWalletConnected,
        pathname: '/profile/settings',
        step: isSelfEmailVerified
          ? {
              target: '#profile-connected-wallets',
              title: 'Connect your Wallet.',
              disableBeacon: true,
              content: <>Start your magical journey by connecting your EVM wallet!</>,
            }
          : {
              target: '#profile-personal-information',
              title: 'Verify your Email.',
              disableBeacon: true,
              content: <>Kickstart your magical journey by verifying your email address!</>,
            },
      },
      {
        name: STEP_NAME.SET_USERNAME_AND_DISPLAYNAME,
        isCompleted: isSelfDisplaynameSet && isSellUsernameSet,
        pathname: '/profile/settings',
        step: {
          placement: 'top',
          target: '#profile-personal-information',
          title: 'Personalize Your Profile.',
          content: <>Add a username and display name to make your profile unique! </>,
        },
      },
      {
        name: STEP_NAME.VERIFY_GITCOIN,
        pathname: '/profile/settings',
        step: {
          target: '#profile-gitcoin-verification',
          title: 'Verify your Gitcoin Passport.',
          placement: 'top',
          content: <>Verify your Gitcoin Passport to unlock exciting rewards!</>,
        },
      },
      {
        name: STEP_NAME.VISIT_HOT_OFFERS_PAGE,
        pathname: '/hot-offers',
        exactPathname: true,
        lockScroll: true,
        step: {
          target: '#onboarding-hot-offers',
          title: 'Meet Hot Offers!',
          placement: 'top',
          content: <>Grab exclusive offers and rewards with our hot offers! Check them out!</>,
        },
      },
      {
        name: STEP_NAME.VISIT_KARMA,
        pathname: '/profile/karma',
        exactPathname: true,
        step: {
          target: '#karma-page-rewards-guide',
          placement: 'top',
          title: 'Discover Magic Karma!',
          content: (
            <>
              Explore the Karma Rewards Guide to discover how to earn Magic Karma on the Magic
              Store!
            </>
          ),
        },
      },
      {
        name: STEP_NAME.VISIT_KARMA_OVERVIEW,
        pathname: '/profile/karma',
        exactPathname: true,
        step: {
          target: '#karma-page-overview',
          placement: 'top',
          title: 'Track Your Karma and Rewards.',
          content: (
            <>Track your earned Karma, SQR, and SQRp rewards, and claim them to your wallet.</>
          ),
        },
      },
      {
        name: STEP_NAME.VISIT_KARMA_LEADERBOARD,
        pathname: '/profile/karma',
        exactPathname: true,
        step: {
          target: '#karma-page-leaderboard',
          placement: 'top',
          title: 'Karma Leaderboard.',
          content: <>See where you stand on the All-time and Daily Karma Leaderboards!</>,
        },
      },
      {
        name: STEP_NAME.VISIT_KARMA_HISTORY,
        pathname: '/profile/karma',
        exactPathname: true,
        step: {
          target: '#karma-page-history',
          placement: 'top',
          title: 'Karma History.',
          content: <>Review your Karma history to see how you've earned it over time!</>,
        },
      },
      {
        name: STEP_NAME.REFER_A_FRIEND,
        pathname: '/profile',
        onBeforeStep: () => {
          if (profileMenu.isAvailable) {
            profileMenu.open();
          }
        },
        isEnabled: profileMenu.isOpen,
        step: {
          target: profileMenu.isAvailable
            ? '[data-onboarding-profile-menu-item="raf"]'
            : '#profile-nav-raf-link',
          title: 'Invite Friends.',
          placement: 'top',
          disableScrolling: true,
          content: (
            <>
              Unlock your Referral Link! Learn how to earn Karma points in the Karma Rewards Guide
              and refer a friend with your unique referral link{' '}
              <NextLink href="/profile/karma-rewards-guide" className="text-primary">
                Learn More
              </NextLink>
            </>
          ),
        },
      },
      {
        name: STEP_NAME.PARTICIPATE_IN_VALIDATION,
        pathname: '/profile',
        onBeforeStep: () => {
          if (profileMenu.isAvailable) {
            profileMenu.open();
          }
        },
        onAfterStep: () => {
          if (profileMenu.isAvailable) {
            profileMenu.close();
          }
        },
        step: {
          target: profileMenu.isAvailable
            ? '[data-onboarding-profile-menu-item="validation"]'
            : '#profile-nav-vote-link',
          title: 'Vote on Projects.',
          placement: 'top',
          content: (
            <>
              Check out projects Under Validation and cast your first vote! Be part of the
              decision-making process!
            </>
          ),
        },
      },
      {
        name: STEP_NAME.EXPLORE_APP,
        pathname: '/app/penguin-finance',
        lockScroll: true,
        step: {
          target: '[data-onboarding="explore-app"]',
          title: 'Explore an App Page.',
          placement: 'top',
          content: (
            <>Explore project details, follow them, leave reviews, and more on the Project Page!</>
          ),
        },
      },
      {
        name: STEP_NAME.VISIT_MAGIC_BOOST,
        onBeforeStep: () => {
          if (storeNavMenu.isAvailable) {
            storeNavMenu.open();
          }
        },
        step: {
          target: storeNavMenu.isAvailable
            ? '[data-onboarding-store-nav-menu-item="magic-boost"]'
            : '#sidebar-navitem-magic-boost',
          placement: 'right',
          title: 'Discover Magic Boost.',
          disableScrolling: true,
          content: <>Discover exclusive offers in the MagicBoost section!</>,
        },
      },
      {
        name: STEP_NAME.VISIT_GITBOOK,
        onBeforeStep: () => {
          if (storeNavMenu.isAvailable) {
            storeNavMenu.open();
          }
        },
        step: {
          target: storeNavMenu.isAvailable
            ? '[data-onboarding-store-nav-menu-item="docs"]'
            : '#sidebar-navitem-gitbook',
          title: 'Visit our Docs.',
          placement: 'right',
          disableScrolling: true,
          content: <>Explore our Gitbook for helpful information!</>,
        },
      },
      {
        name: STEP_NAME.SUPPORT,
        onBeforeStep: () => {
          if (storeNavMenu.isAvailable) {
            storeNavMenu.open();
          }
        },
        onAfterStep: () => {
          if (storeNavMenu.isAvailable) {
            storeNavMenu.close();
          }
        },
        step: {
          target: storeNavMenu.isAvailable
            ? '[data-onboarding-store-nav-menu-item="support"]'
            : '#sidebar-navitem-support',
          title: 'Contact Support.',
          placement: 'right',
          disableScrolling: true,
          content: <>One last thing! If you ever need support, you can find it here!</>,
        },
      },
    ];

    return memoizedSteps;
  }, [
    isSelfEmailVerified,
    isSelfEVMWalletConnected,
    isSelfDisplaynameSet,
    isSellUsernameSet,
    profileMenu.isAvailable,
    profileMenu.open,
    storeNavMenu.isAvailable,
    storeNavMenu.open,
  ]);
};

export default useOnboardingSteps;

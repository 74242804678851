import { ComponentPropsWithoutRef, ComponentType } from 'react';

import classNames from 'classnames';

import CampaignsIcon from '@uikit/icons/Campaigns';
import GoldenStar from '@uikit/icons/GoldenStar';
import MagicBoardIcon from '@uikit/icons/MagicBoard';
import MultiColorFire from '@uikit/icons/MultiColorFire';
import Search from '@uikit/icons/Search';

import { MsqLogo } from '@shared/constants/icons';

// eslint-disable-next-line @typescript-eslint/ban-types
export type NavbarIconName = ComponentType<{ isActive?: boolean } | {}>;

interface NavbarItemIconRootProps extends ComponentPropsWithoutRef<'span'> {
  isActive?: boolean;
}

const NavbarItemIconRoot = ({ isActive, className, children }: NavbarItemIconRootProps) => {
  return (
    <span
      className={classNames(
        'w-full h-full flex p-0.5 justify-center items-center rounded-full',
        isActive && 'bg-primary-200',
        className,
      )}
    >
      {children}
    </span>
  );
};

export const NavHotOffer = ({ isActive }: { isActive?: boolean }) => {
  return (
    <NavbarItemIconRoot>
      <span
        className={classNames('icon-[100%] p-1 rounded-full', {
          'border border-secondary-600 drop-shadow-nav-item': isActive,
          'bg-secondary-100': !isActive,
        })}
      >
        <MultiColorFire />
      </span>
    </NavbarItemIconRoot>
  );
};

export const NavMagicBoardIcon = ({ isActive }: { isActive?: boolean }) => {
  return (
    <NavbarItemIconRoot>
      <span
        className={classNames('icon-[100%] p-1 rounded-full', {
          'border border-primary-500 drop-shadow-nav-item': isActive,
          'bg-[#DDEBFD]': !isActive,
        })}
      >
        <MagicBoardIcon />
      </span>
    </NavbarItemIconRoot>
  );
};

export const NavRewardPoolsIcon = ({ isActive }: { isActive?: boolean }) => {
  return (
    <NavbarItemIconRoot>
      <span
        className={classNames('icon-[100%] p-1 rounded-full', {
          'border border-primary-500 drop-shadow-nav-item': isActive,
          'bg-[#F3F5FF]': !isActive,
        })}
      >
        <GoldenStar />
      </span>
    </NavbarItemIconRoot>
  );
};

export const NavCampaignsIcon = ({ isActive }: { isActive?: boolean }) => {
  return (
    <NavbarItemIconRoot>
      <span
        className={classNames('icon-[100%] p-1 rounded-full', {
          'border border-yellow-600 drop-shadow-nav-item': isActive,
          'bg-[#FEF0CD]': !isActive,
        })}
      >
        <CampaignsIcon />
      </span>
    </NavbarItemIconRoot>
  );
};

export const NavSearch = ({ isActive }: { isActive?: boolean }) => {
  return (
    <NavbarItemIconRoot isActive={isActive}>
      <span className={classNames('icon-[100%] text-primary-700')}>
        <Search />
      </span>
    </NavbarItemIconRoot>
  );
};

export const NavTokenIcon = () => {
  return (
    <NavbarItemIconRoot>
      <span className="icon-[100%] rounded-full p-1 bg-neutral-600">
        <MsqLogo />
      </span>
    </NavbarItemIconRoot>
  );
};

const navbarIcons = {
  NavHotOffer,
  NavSearch,
  NavMagicBoardIcon,
  NavCampaignsIcon,
};

export interface NavbarItemIconProps extends NavbarItemIconRootProps {
  Icon: NavbarIconName;
}

const NavbarItemIcon = ({ isActive, Icon, ...props }: NavbarItemIconProps) => {
  // TODO: refactor
  if (
    navbarIcons.NavHotOffer === Icon ||
    navbarIcons.NavSearch === Icon ||
    navbarIcons.NavMagicBoardIcon === Icon ||
    navbarIcons.NavCampaignsIcon === Icon
  ) {
    return <Icon isActive={isActive} {...props} />;
  }

  return (
    <NavbarItemIconRoot isActive={isActive} {...props}>
      <span className="icon-[100%] drop-shadow-nav-item">
        <Icon />
      </span>
    </NavbarItemIconRoot>
  );
};

export default NavbarItemIcon;

import Link from 'next/link';

import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';

import classNames from 'classnames';

interface ProfileLayoutSidebarNavItemGeneralProps {
  children: ReactNode;
  isActive?: boolean;
  variant?: 'primary' | 'secondary';
  className?: string;
}

export type ProfileLayoutSidebarNavItemProps<C extends ElementType> = {
  Component?: C;
} & ProfileLayoutSidebarNavItemGeneralProps &
  ComponentPropsWithoutRef<C>;

const ProfileLayoutSidebarNavItem = <C extends ElementType = typeof Link>({
  children,
  Component,
  className,
  variant = 'primary',
  isActive,
  disabled,
  isSub,
  ...props
}: ProfileLayoutSidebarNavItemProps<C>) => {
  const As = Component || Link;

  return (
    <As
      className={classNames(
        'flex items-center min-h-[2.25rem] pr-1.5 rounded-full transition-colors',

        ...(variant === 'primary'
          ? ['t-button hover:bg-primary-100 pl-3', isActive && 'text-primary bg-primary-100']
          : []),

        ...(variant === 'secondary'
          ? [
              't-body4 font-medium text-[0.8125rem] pl-6',
              {
                'text-neutral-300 cursor-not-allowed': disabled,
                'hover:text-primary': !disabled,
                'text-primary': isActive,
              },
            ]
          : []),

        className,
      )}
      // TODO: fix type
      {...(props as any)}
    >
      {children}
    </As>
  );
};

export default ProfileLayoutSidebarNavItem;

import { MouseEvent } from 'react';

import { Dropdown, DropdownItem } from '@uikit';

import { useSqrTokenPriceQuery } from '@query';

import { sqrTokenSourcesInOrder } from '@shared/constants/sqrTokenSources';
import { formatNumberAsCurrency } from '@shared/helpers/formatters';

import BanxaButton from '@store:web/components/common/modals/BanxaModal/BanxaButton';

import NavbarItem from './NavbarItem';
import NavbarItemIcon, { NavTokenIcon } from './NavbarItemIcon';

const REFETCH_INTERVAL = 10 * 60 * 1000;

const NavbarSqrTokenProvidersDropdown = () => {
  const { data, isPending: isLoading } = useSqrTokenPriceQuery({
    refetchInterval: REFETCH_INTERVAL,
  });

  const price = data?.attributes.price;
  const formattedPrice = formatNumberAsCurrency({
    amount: price ? price : 0,
    maximumFractionDigits: 2,
  });

  return (
    <div className="max-md:hidden">
      <Dropdown
        trigger={({ triggerProps: { onClick, ...triggerProps } }) => {
          const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            if (onClick && typeof onClick === 'function') {
              onClick(event);
            }
          };

          return (
            <NavbarItem
              Component="button"
              // NOTE: this is a quick fix to hide focus, because it appears on scroll when dropdown hides
              // TODO: find better way to handle above case
              className="focus:outline-none"
              label={
                <>
                  {!isLoading && (
                    <>
                      <span className="text-secondary-700 text-xs font-medium">$SQR</span>{' '}
                      <span className="text-neutral-600">{formattedPrice}</span>
                    </>
                  )}

                  {isLoading && <>Loading...</>}
                </>
              }
              onClick={handleClick}
              {...triggerProps}
            >
              <NavbarItemIcon Icon={NavTokenIcon} className="text-primary" />
            </NavbarItem>
          );
        }}
      >
        <BanxaButton />
        {sqrTokenSourcesInOrder.map(({ label, Icon, href }) => {
          return (
            <DropdownItem
              Component="a"
              key={label}
              className="flex items-center gap-2"
              label={label}
              href={href}
              target="_blank"
            >
              <Icon className="icon" />
              {label}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </div>
  );
};

export default NavbarSqrTokenProvidersDropdown;

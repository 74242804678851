import { usePathname } from 'next/navigation';

import { ReactNode, UIEventHandler, useLayoutEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { Collapse } from '@uikit';

import AngleDown from '@uikit/icons/AngleDown';

import { ProfileNavItemWithLink } from './ProfileLayoutSidebarNav';
import ProfileLayoutSidebarNavItem from './ProfileLayoutSidebarNavItem';
import ProfileLayoutSidebarNavLink from './ProfileLayoutSidebarNavLink';

interface ProfileLayoutSidebarSubNavProps {
  label: ReactNode;
  hasBottomDivider?: boolean;
  items: ProfileNavItemWithLink[];
}

const ProfileLayoutSidebarSubNav = ({
  label,
  items,
  hasBottomDivider,
}: ProfileLayoutSidebarSubNavProps) => {
  const pathname = usePathname();
  const hasActiveItem = useMemo(
    () => items.some((item) => item.href === pathname),
    [pathname, items],
  );
  const [isOpen, setIsOpen] = useState(hasActiveItem);
  const toggle = () => setIsOpen((oldIsOpen) => !oldIsOpen);

  useLayoutEffect(() => {
    if (!hasActiveItem) {
      setIsOpen(false);
    }
  }, [hasActiveItem]);

  const handleFocus = () => {
    setIsOpen(true);
  };

  // NOTE: prevents scrolling that happens during focusing on elements when collapse is closed
  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    if ('scrollTop' in e.target) {
      e.target.scrollTop = 0;
    }
  };

  return (
    <div
      className={classNames('flex flex-col', {
        'gap-1': hasBottomDivider,
      })}
    >
      <ProfileLayoutSidebarNavItem
        Component="button"
        isActive={hasActiveItem || isOpen}
        onClick={toggle}
      >
        <span className="flex items-center flex-grow">{label}</span>
        <AngleDown className={classNames('icon transition-transform', isOpen && 'rotate-180')} />
      </ProfileLayoutSidebarNavItem>
      <Collapse
        isOpen={isOpen}
        className={classNames(
          'border-b ',
          isOpen
            ? 'border-b-primary'
            : hasBottomDivider
            ? 'border-b-neutral-100'
            : 'border-b-transparent',
        )}
      >
        <div onScroll={handleScroll} className="-mx-2 px-2 -mt-2">
          <nav className="flex flex-col pt-2 gap-1" onFocus={handleFocus}>
            {items.map((item) => {
              if (!item.href) {
                return (
                  <ProfileLayoutSidebarNavItem
                    Component="button"
                    key={item.id}
                    variant="secondary"
                    disabled
                  >
                    {item.label}
                  </ProfileLayoutSidebarNavItem>
                );
              }

              return (
                <ProfileLayoutSidebarNavLink
                  key={item.href}
                  href={item.href}
                  target={item.target}
                  variant="secondary"
                >
                  {item.label}
                </ProfileLayoutSidebarNavLink>
              );
            })}
          </nav>
        </div>
      </Collapse>
    </div>
  );
};

export default ProfileLayoutSidebarSubNav;

'use client';

import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { storeNavConfig } from '@store:web/utils/config/store';

import Navbar from '../Navbar/Navbar';
import NavbarLink from '../Navbar/NavbarLink';

type TopNavbarProps = ComponentPropsWithoutRef<'nav'>;

const bottomNavbarItems = [
  storeNavConfig.discover,
  storeNavConfig.apps,
  storeNavConfig.games,
  storeNavConfig.upcoming,
  storeNavConfig.search,
];

const BottomNavbar = ({ className, ...props }: TopNavbarProps) => {
  return (
    <Navbar className={classNames('shadow-bottom-navbar', className)} {...props}>
      {bottomNavbarItems.map((navItem) => {
        return <NavbarLink key={navItem.href} compact {...navItem} />;
      })}
    </Navbar>
  );
};

export default BottomNavbar;

import { useLayoutEffect, useRef, useState } from 'react';

// TODO: come up with better naming
export const useHideOnScroll = <T extends HTMLElement>() => {
  const [isHidden, setIsHidden] = useState(false);
  const targetRef = useRef<T>(null);

  useLayoutEffect(() => {
    let prevScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const headerHeight = targetRef.current?.offsetHeight || 0;

      setIsHidden(currentScrollY >= headerHeight && currentScrollY >= prevScrollY);

      prevScrollY = currentScrollY;
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    setIsHidden,
    isHidden,
    targetRef,
  };
};
